"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _emotion = require("emotion");

var _templateObject, _templateObject2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var rotate360 = (0, _emotion.keyframes)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n"])));
var spinnerDash = (0, _emotion.keyframes)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  0% {\n    stroke-dasharray: 1,200;\n    stroke-dashoffset: 0;\n  }\n  50% {\n    stroke-dasharray: 89,200;\n    stroke-dashoffset: -35px;\n  }\n  100% {\n    stroke-dasharray: 89,200;\n    stroke-dashoffset: -124px;\n  }\n"])));
var _default = {
  wrapper: function wrapper(state) {
    return _objectSpread({
      position: 'relative'
    }, state);
  },
  overlay: function overlay(state, props) {
    return {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0px',
      left: '0px',
      display: 'flex',
      textAlign: 'center',
      fontSize: '1.2em',
      color: '#FFF',
      background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 800,
      transition: "opacity ".concat(props.fadeSpeed, "ms ease-in"),
      opacity: state === 'entering' || state === 'entered' ? 1 : 0
    };
  },
  content: function content() {
    return {
      margin: 'auto'
    };
  },
  spinner: function spinner(state) {
    return {
      position: 'relative',
      margin: '0px auto 10px auto',
      width: '50px',
      maxHeight: '100%',
      '&:before': {
        content: '""',
        display: 'block',
        paddingTop: '100%'
      },
      '& svg': {
        animation: "".concat(rotate360, " 2s linear infinite"),
        height: '100%',
        transformOrigin: 'center center',
        width: '100%',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        margin: 'auto',
        '& circle': {
          animation: "".concat(spinnerDash, " 1.5s ease-in-out infinite"),
          strokeDasharray: '1,200',
          strokeDashoffset: 0,
          strokeLinecap: 'round',
          stroke: '#FFF'
        }
      }
    };
  }
};
exports["default"] = _default;